/* global autosize moment Highcharts LANGUAGE tinymce ol */


// #############################################################################
// AJAX SETUP

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  beforeSend: function(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader(
        'X-CSRFToken', $('[name=csrfmiddlewaretoken]').val()
      );
    }
  },
  error: function(e) {
    console.log('ERROR:', e, e.status, e.statusText);
  }
});


// #############################################################################
// GLOBAL VARS

const $window = $(window);
const $body = $('body');


// #############################################################################
// MOMENTS

moment.locale($('html').attr('lang'));


// #############################################################################
// FOCUS

function initFocus() {
  let focus_method = false;
  let last_focus_method = false;

  $body.on('focus', 'a, [tabindex]', function() {
    if (!focus_method) {
      focus_method = last_focus_method;
    }

    $('a, [tabindex]').attr('data-focus-method', focus_method);

    last_focus_method = focus_method;
    focus_method = false;
  });

  $body.on('blur', 'a, [tabindex]', function() {
    $('a, [tabindex]').removeAttr('data-focus-method');
  });

  $window.on('blur', function() {
    focus_method = false;
  });

  // Keyboard

  $body.on('keydown', 'a, [tabindex]', function() {
    focus_method = 'key';
  });

  // Mouse

  $body.on('mousedown', 'a, [tabindex]', function() {
    if (focus_method === 'touch') {
      return;
    }

    focus_method = 'mouse';
  });

  // Touch

  $body.on('touchstart', 'a, [tabindex]', function() {
    focus_method = 'touch';
  });
}

initFocus();


// #############################################################################
// TOAST

function updateToast($data) {
  if ($data['message']) {
    $.toast({
      hideAfter: 3000,
      icon: $data['submit'],
      stack: 2,
      text: $data['message'],
    });
  }
}


// #############################################################################
// FORM VALIDATION (OLD)

(function($) {
  'use strict';

  let pluginName = 'formValidation';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$success_info = this.$el.find('[data-form-success-info]');
    this.$inputs = this.$el.find('[data-required], [required]');
    this.has_ajax_validation = this.$el.attr('data-form-validation') == 'ajax';
    this.submitUrl = this.$el.attr('action');

    this.init();
  }

  $.extend(Plugin.prototype, {
    init: function() {
      let _this = this;

      _this.checkFormValidity();

      _this.$inputs.on('blur input', function() {
        _this.checkInputValidity(_this.$inputs.index(this));
      });
    },

    checkFormValidity: function() {
      let _this = this;

      _this.$el.on('submit', function() {
        _this.focusFirstInvalidInput();

        if (!_this.$element.checkValidity()) {
          return false;
        }

        _this.$el.addClass('was-validated');

        _this.$inputs.each(function(index) {
          _this.checkInputValidity(index);
        });
      });
    },

    checkInputValidity: function(index) {
      let _this = this;
      let $input = _this.$inputs.eq(index);
      let $label = $('[for="' + $input.attr('id') + '"]');
      let $error = $label.find('.map-form-error');

      if ($input[0].checkValidity()) {
        $input.removeClass('is-invalid');
        $error.addClass('d-none');
        return;
      }

      $input.addClass('is-invalid');
      $error.removeClass('d-none');
      _this.$success_info.addClass('d-none');

      _this.setErrorMessages(index);
    },

    setErrorMessages: function(index) {
      let _this = this;

      if (!_this.has_ajax_validation) {
        return false;
      }

      $.ajax({
        type: 'POST',
        url: this.submitUrl,
        data: _this.$el.serializeArray()
      }).done(function($data) {
        let $input = _this.$inputs.eq(index);
        let input_name = $input.attr('name');
        let $error = $('#' + input_name + '_error');

        if ($data[input_name]) {
          $error.text($data[input_name].join(' '));
        }
      });
    },

    focusFirstInvalidInput: function() {
      let _this = this;

      _this.$inputs.each(function(index) {
        let $input = _this.$inputs.eq(index);

        if ($input.is(':invalid')) {
          $input.focus();
          return false;
        }
      });
    }
  });

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };
})(jQuery);

$('[data-form-validation]').formValidation();


// #############################################################################
// FORM: VALIDATION

function initFormValidation() {

  // Insert error message

  function insertErrorMessage($input, errors) {
    if (!errors) {
      return;
    }

    let input_name = $input.attr('name');
    let $error = $('#id_' + input_name + '_error');

    if ($input.is(':checkbox') || $input.is(':radio')) {
      $error = $('#id_' + input_name + '_0_error');
    }

    if (errors[input_name]) {
      $input.addClass('is-invalid');
      $error.text(errors[input_name].join(' '));
    }
  }

  // Validate input

  function checkInputValidity($input) {
    if ($input[0].checkValidity()) {
      let input_name = $input.attr('name');
      let $error = $('#id_' + input_name + '_error');

      if ($input.is(':radio')) {
        $input = $('[name="' + input_name + '"]');
        $error = $('#id_' + input_name + '_0_error');
      }

      $input.removeClass('is-invalid');
      $error.empty();

      return true;
    }

    return false;
  }

  // Validate before submit

  $body.on('blur input', '[data-form] :input:visible:not(button)', function() {
    let $input = $(this);
    let is_valid = checkInputValidity($input);

    // if (!is_valid) {
      let $form = $input.parents('[data-form]');

      $.ajax({
        contentType: false,
        data: new FormData($form[0]),
        enctype: 'multipart/form-data',
        processData: false,
        type: 'POST',
        url: $form.attr('action'),
        success: function(data) {
          insertErrorMessage($input, data['errors']);
        },
      });
    // }
  });

  // Validate after submit

  $body.on('submit', '[data-form]', function(event) {
    event.preventDefault();

    let $form = $(this);
    let $inputs = $(':input:not(button)', $form);

    if (!$form[0].checkValidity()) {
      $form.find('[required]:invalid').first().focus();

      $inputs.each(function(index) {
        checkInputValidity($inputs.eq(index));
      });
    }

    let $form_data = new FormData($form[0]);

    $form_data.append('submit', 1);

    $.ajax({
      contentType: false,
      data: $form_data,
      enctype: 'multipart/form-data',
      processData: false,
      type: 'POST',
      url: $form.attr('action'),
      success: function($data) {
        reloadDatatable($data);
        updateToast($data);

        console.log($data);

        if ($data['submit'] === 'error') {
          $inputs.each(function(index) {
            insertErrorMessage($inputs.eq(index), $data['errors']);
          });
        }
        else {
          console.log("hideModal")
          hideModal();
        }
      },
    });
  });
}

initFormValidation();


// #############################################################################
// FORM: RELATIONSHIP

function initRelationship() {
  let $relationships = $('[data-relationship-trigger]');

  function relationshipTrigger(el) {
    let $input = $(el);
    let value = $input.is(':checkbox') ? $input.prop('checked') : $input.val();
    let $activates = $input.data('relationship-activate').split(',');

    $activates.forEach(function(id) {
      let $acticate = $('#' + id);

      if (value) {
        $acticate.removeClass('d-none');
      }
      else {
        $acticate.addClass('d-none');
      }
    });
  }

  $relationships.each(function() {
    relationshipTrigger(this);
  });

  $relationships.on('input', function() {
    relationshipTrigger(this);
  });

  // Unit

  let $units = $('[data-unit-trigger]');

  function unitTrigger(el) {
    let $input = $(el);
    let $trigger = $('#id_' + $input.data('unit-trigger'));
    let unit = $('option:selected', $input).data('unit');

    $trigger.val(unit);
  }

  $units.each(function() {
    unitTrigger(this);
  });

  $units.on('input', function() {
    unitTrigger(this);
  });

  // Monthly consumption

  let $monthly_consumptions = $('[data-monthly-consumption]');

  function monthlyConsumption(el) {
    let $input = $(el);
    let $trigger = $('#id_' + $input.data('monthly-consumption'));

    if ($input.prop('checked')) {
      $trigger.prop('disabled', 'disabled');
    }
    else {
      $trigger.prop('disabled', '');
    }
  }

  $monthly_consumptions.each(function() {
    monthlyConsumption(this);
  });

  $monthly_consumptions.on('input', function() {
    monthlyConsumption(this);
  });
}

initRelationship();



// #############################################################################
// FORM: TEXTAREA

autosize($('textarea'));


// #############################################################################
// MODAL

function hideModal() {
  let $wrapper = $('#modal_wrapper');

  if ($wrapper.length !== 0) {
    $('[data-modal]', $wrapper).modal('hide');
  }
}

function initModal() {
  let $wrapper = $('#modal_wrapper');

  if ($wrapper.length === 0) {
    return;
  }

  $body.on('click', '[data-modal-link]', function() {
    $('[data-modal]', $wrapper).modal('dispose');

    $.ajax({
      url: this.href,
      success: function($data) {
        if ($.type($data) === 'string') {
          $wrapper.empty().html($data);
          $('[data-modal]', $wrapper).modal();

          autosize($('[data-modal] textarea'));

          initHtmlTextarea();

          initRelationship();
        }
        else {
          reloadDatatable($data);
          updateToast($data);
        }
      },
    });

    return false;
  });
}

initModal();


// #############################################################################
// TOOLTIP

function initToolTip() {
  $('[data-toggle="tooltip"]').tooltip();
}

initToolTip();


// #############################################################################
// DATA TABLES

function reloadDatatable($data) {
  if ($data['datatable']) {
    let top = $window.scrollTop();

    window.datatable.ajax.reload(function() {
      $window.scrollTop(top);
    }, false);
  }
}

function initDataTable(table, $options, $buttons = []) {
  const $table = $('[data-' + table + ']');
  const $inputs = $('[data-input]', $table);
  const $selects = $('[data-select]', $table);
  const $checkboxes = $('[data-checkbox]', $table);

  if ($table.length === 0) {
    return;
  }

  // Custom classes

  $.fn.dataTable.ext.classes.sInfo = 'dataTables_info ml-2';
  $.fn.dataTable.ext.classes.sLength = 'dataTables_length';
  $.fn.dataTable.ext.classes.sLengthSelect = 'custom-select';
  $.fn.dataTable.ext.classes.sPageButton = 'page-item page-link';
  $.fn.dataTable.ext.classes.sPageButtonActive = 'active';
  $.fn.dataTable.ext.classes.sProcessing = 'dataTables_processing text-center p-2 position-absolute';
  $.fn.dataTable.ext.classes.sSortAsc = 'sorting-asc';
  $.fn.dataTable.ext.classes.sSortColumn = 'sorting-';
  $.fn.dataTable.ext.classes.sSortDesc = 'sorting-desc';
  $.fn.dataTable.ext.classes.sTable = 'datatable-table';

  let $default_buttons = [
    {
      className: 'btn btn-secondary font-weight-bold',
      text: LANGUAGE['resetButton'],
      action: function(e, $dt) {
        $dt.columns().search('').draw();

        $inputs.val('');

        $selects.each(function(index) {
          let $select = $selects.eq(index);
          let value = $('[selected]', $select).val();

          if (value) {
            $select.val(value);
          } else {
            $select.val('');
          }
        });
      }
    },
    {
      className: 'btn btn-secondary font-weight-bold',
      extend: 'csv',
      exportOptions: {
        columns: 'thead th:not(.no-export)',
      },
      text: LANGUAGE['exportCSV'],
      init: function($dt, $node) {
        let $export_csv = $('[data-export-csv]', $body).length;

        if ($export_csv === 0) {
          $node.addClass('d-none');
        }
      },
      action: function(e, $dt, $node, config) {
        let $inputs = $('tr:first input[type="checkbox"]', $dt.body().toJQuery());
        let $all_inputs = $('tr input[type="checkbox"]', $dt.body().toJQuery());

        self.triggers = [];
        self.checked = [];

        $inputs.each(function(index) {
          let $input = $inputs.eq(index);

          self.triggers.push($input.data('csv-trigger'));
        });

        $all_inputs.each(function(index) {
          let $input = $all_inputs.eq(index);

          self.checked.push(
            $input.is(':checked') ? LANGUAGE['yes'] : LANGUAGE['no']
          );
        });

        self.checked.reverse();

        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, $dt, $node, config);
      },
      customize: function(csv) {
        let $split_csv = csv.split('\n');

        $.each($split_csv.slice(1), function(index, csv_row) {
          let $csv_cell_array = csv_row.split('","');
          let $new_csv_cell_array = [];
          // Remove replace the two quotes which are left at the beginning and the end (first and last cell)

          $csv_cell_array[0] = $csv_cell_array[0].replace(/"/g, '');
          $csv_cell_array[$csv_cell_array.length - 1] = $csv_cell_array[$csv_cell_array.length - 1].replace(/"/g, '');

          $.each($csv_cell_array, function(index) {
            if (self.triggers.indexOf(index) > -1) {
              $csv_cell_array[index] = self.checked.pop();
            }

            $new_csv_cell_array.push($csv_cell_array[index]);
          });

          $split_csv[index + 1] = '"' + $new_csv_cell_array.join('","') + '"';
        });

        csv = $split_csv.join('\n');

        return csv;
      },
    },
  ];

  $.merge($default_buttons, $buttons);

  function initFilters(table) {
    let $api = table.api();
    let $state = $api.state.loaded();

    // Restore saved filter values

    if ($state) {
      $api.columns().eq(0).each(function(index) {
        let $search = $state.columns[index].search;

        if ($search) {
          let search = $search.search;

          if (search) {
            let $input = $('[data-column="' + index + '"]');

            if ($input.is(':checkbox')) {
              $input.prop('checked', false);

              if (search === 'true') {
                $input.prop('checked', true);
              }
            }
            else {
              $('[data-column="' + index + '"]').val(search);
            }
          }
        }
      });
    }

    // Filter

    let timeout;

    $('.datatable-select').attr(
      'aria-label', LANGUAGE['entries']
    ).after('<div class="field-select-overlay">');

    $inputs.on('input', function() {
      let $input = $inputs.eq($inputs.index(this));
      let $column = $api.column($input.data('column'));
      let value = this.value;

      if ($input.is(':checkbox')) {
        $column.search($input.is(':checked')).draw();
      }
      else {
        clearTimeout(timeout);

        timeout = setTimeout(function() {
          $column.search(value).draw();
        }, 200);
      }
    });

    $selects.on('change', function() {
      let $select = $selects.eq($selects.index(this));
      let column = $select.data('column');
      let $column = $api.column(column);

      $column.search($select.val()).draw();
    });
  }

  function allChecked($api, column) {
    let $column = $api.column(column);
    let $nodes = $column.nodes().toJQuery();

    if ($nodes.length === 0) {
      return false;
    }

    return $nodes.length == $('input:checked', $nodes).length;
  }

  function initSelectAllCheckboxes(table) {
    let $api = table.api();

    $checkboxes.on('input', function() {
      let $checkbox = $checkboxes.eq($checkboxes.index(this));
      let all_checked = $checkbox.is(':checked');
      let column = $checkbox.data('trigger-column');
      let $column = $api.column(column);
      let $nodes = $column.nodes().toJQuery();
      let $ids = [];

      $nodes.each(function(index) {
        $ids.push($('[data-id]', $nodes.eq(index)).data('id'));
      });

      let $form_data = new FormData();

      $form_data.append('ids', $ids);
      $form_data.append('checked', all_checked);

      $.ajax({
        contentType: false,
        data: $form_data,
        processData: false,
        type: 'POST',
        url: $checkbox.data('checkbox'),
        success: function($data) {
          let value = $('[data-column="' + column + '"]', table).val();

          $('[data-trigger="' + column + '"]', table).prop(
            'checked', all_checked
          );

          updateToast($data);

          if (value) {
            reloadDatatable($data);
          }
        },
      });
    });
  }

  function initCheckboxes(table) {
    let $api = table.api();
    let $all_checkboxes = $('[data-trigger]', $table);

    $checkboxes.each(function(index) {
      let $checkbox = $checkboxes.eq(index);
      let column = $checkbox.data('trigger-column');
      let all_checked = allChecked($api, column);

      $checkbox.prop('checked', all_checked);
    });

    $all_checkboxes.on('input', function() {
      let $checkbox = $(this);
      let column = $checkbox.data('trigger');
      let $form = $checkbox.parents('form');
      let all_checked = allChecked($api, column);

      $.ajax({
        contentType: false,
        data: new FormData($form[0]),
        enctype: 'multipart/form-data',
        processData: false,
        type: 'POST',
        url: $form.attr('action'),
        success: function($data) {
          let value = $('[data-column="' + column + '"]', table).val();

          $('[data-trigger-column="' + column + '"]', table).prop(
            'checked', all_checked
          );

          updateToast($data);

          if (value) {
            reloadDatatable($data);
          }
        },
      });
    });
  }

  let $defaults = {
    autoWidth: false,
    ajax: {
      url: $table.data(table),
    },
    buttons: {
      buttons: $default_buttons,
    },
    columnDefs: [
      {
        'sortable': false,
        'targets': 'no-sort',
      }
    ],
    dom: '<"row"<"col-auto"li><"col"B><"col-auto ml-auto"p><"col-12"rt><"col"li><"col-auto ml-auto"p>',
    language: {
      sEmptyTable: LANGUAGE['sEmptyTable'],
      sZeroRecords: LANGUAGE['sZeroRecords'],
      sInfo: LANGUAGE['sInfo'],
      sInfoEmpty: LANGUAGE['sInfoEmpty'],
      sInfoThousands: '.',
      sLengthMenu: LANGUAGE['sLengthMenu'],
      sProcessing: LANGUAGE['sProcessing'],
      aria: {
        sortDescending: LANGUAGE['oAria']['sortDescending'],
        sortAscending: LANGUAGE['oAria']['sortAscending'],
      },
    },
    lengthMenu: [
      [10, 50, 100, -1],
      [10, 50, 100, LANGUAGE['all']],
    ],
    ordering: true,
    pagingType: 'numbers',
    processing: true,
    responsive: false,
    serverSide: true,
    stateSave: true,
    drawCallback: function() {
      initCheckboxes(this);
    },
    initComplete: function() {
      initFilters(this);
      initSelectAllCheckboxes(this);
    }
  }

  $.extend($defaults, $options);

  window.datatable = $table.DataTable($defaults);

  return window.datatable
}


// #############################################################################
// DATA TABLES: AGWR

function initDataTableAGWR() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        data: 'id',
      },
      {
        data: 'name',
      },
      {
        data: 'is_community_building',
      },
      {
        data: 'street',
      },
      // {
      //   data: 'house_number',
      // },
      {
        data: 'building',
      },
      {
        data: 'sensors',
      },
      {
        class: 'pl-0',
        data: 'dashboard',
      },
      {
        class: 'pl-0',
        data: 'ea_list',
      },
      {
        class: 'pl-0',
        data: 'add_user',
      },
      {
        class: 'pl-0',
        data: 'edit',
      },
    ],
  };

  initDataTable('agwr-table', $options);
}

initDataTableAGWR();


// #############################################################################
// DATA TABLES: AGWR DASHBOARD

function initDataTableAGWRDashboard() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        class: 'd-none',
        data: 'input_type',
      },
      {
        class: 'align-middle',
        data: 'name',
      },
      {
        class: 'align-middle text-right',
        data: 'value',
      },
      {
        class: 'align-middle',
        data: 'chart',
      },
      {
        class: 'align-middle pl-0',
        data: 'reading',
      },
      {
        class: 'align-middle px-0',
        data: 'icons',
      },
    ],
    dom: '<"row"<"col-12"rt>',
    paging: false,
  };

  let $table = initDataTable('agwr-dashboard-table', $options);

  if ($table) {
    $table.on('draw', function() {
      let last = null;

      let $rows = $table.rows({
        page: 'current'
      }).nodes();

      let $groups = $table.column(0, {
        page: 'current'
      }).data();

      let $tds = $('td', $($rows));

      $tds.each(function(index) {
        let $td = $tds.eq(index);

        if ($td.html().trim() === '' || $td.html().trim() === '-') {
          $td.addClass('d-none');
        }
      });

      $groups.each(function(group, i) {
        if (last !== group) {
          $($rows).eq(i).before(
            '<tr class="bg-gray-100 group"><th colspan="5">' + group + '</th></tr>'
          );

          last = group;
        }
      });

      // Charts

      sessionStorage.setItem('dashboard_chart_data', '');

      initVisibleChart();

      $('[data-chart-input]').on('click', function() {
        let $input = $(this);

        disableChartCheckbox($input);
        updateVisibleChart($input);
        createChart($input);
      });

      // Public or approved

      $('[data-readings-public], [data-readings-approved]').on('click', function() {
        let $input = $(this);
        let $form = $input.parents('form');

        $.ajax({
          contentType: false,
          data: new FormData($form[0]),
          processData: false,
          type: 'POST',
          url: $form.attr('action'),
        }).done(function($data) {
          reloadDatatable($data);
          updateToast($data);
        });
      });
    });
  }

  function initChartView($data) {
    let $chart_view_wrapper = $('#chart_view_wrapper');

    if ($data['chart'] == 'bar') {
      $chart_view_wrapper.addClass('d-none');
    }
    else {
      $chart_view_wrapper.removeClass('d-none');
    }
  }

  function disableChartCheckbox($input) {
    let unit = $input.data('input-unit');
    // Todo: Ausnahme Energieausweis
    // let energy_type = $('#energy_type :selected');
    // console.log('test', energy_type)
    // if( energy_type.val() === ''){

    if($('[data-input-unit]:checked').length === 0) {
      $('[data-input-unit]').prop('disabled', false);
    } else {
      $('input[data-input-unit="' + unit + '"]').prop('disabled', false);
      $('input[data-chart-input]:not([data-input-unit="' + unit + '"])').prop('disabled', true);
    }
    // }
  }

  function initVisibleChart() {
    let $visible_chart = localStorage.getItem('dashboard_visible_chart');

    if ($visible_chart) {
      $visible_chart =  JSON.parse($visible_chart);
      let $create = [];

      $visible_chart.forEach(function(item) {
        let $input = $('#' + item);

        if ($input.length > 0) {
          setTimeout(function() {
            $input.click();
          }, 10);

          disableChartCheckbox($input);
          $create.push($input)
        }
      });

      $create.forEach(function($item, index) {
        let last_item = $create.length - 1 === index;

        createChart($item, last_item);
      });
    }
  }

  function updateVisibleChart($input) {
    let $visible_chart = localStorage.getItem('dashboard_visible_chart');

    let id = $input.attr('id');
    let is_checked = $input.prop('checked');

    if ($visible_chart) {
      $visible_chart =  JSON.parse($visible_chart);
    }
    else {
      $visible_chart = [];
    }

    if (is_checked) {
      if ($visible_chart.indexOf(id) < 0) {
        $visible_chart.push(id);
      }
    }
    else {
      let $visible_chart_new = [];

      $visible_chart.forEach(function(item) {
        if (item != id) {
          $visible_chart_new.push(item);
        }
      });

      $visible_chart = $visible_chart_new;
    }

    localStorage.setItem('dashboard_visible_chart', JSON.stringify($visible_chart));
  }

  function createChart($input, last_item = true) {
    let $form = $input.parents('form');
    let id = $input.attr('id');
    let is_checked = $input.prop('checked');

    function calcLineHightChart($data) {
      let $chart_data = sessionStorage.getItem('dashboard_chart_data');

      if ($chart_data) {
        $chart_data = JSON.parse($chart_data);
      } else {
        $chart_data = []
      }

      let $chart_data_item = {
        'id': id,
        'data': $data,
      };

      if (is_checked) {
        let $ids = [];

        $chart_data.forEach(function ($item) {
          $ids.push($item['id']);
        });

        if ($ids.indexOf(id) < 0) {
          $chart_data_item['data']['name'] = $input.data('input-type') + '<br>' + $chart_data_item['data']['name'];
          $chart_data.push($chart_data_item);
        }
      }
      else {
        let $chart_data_new = [];

        $chart_data.forEach(function ($item) {
          if ($item['id'] != id) {
            $chart_data_new.push($item);
          }
        });

        $chart_data = $chart_data_new;
      }

      sessionStorage.setItem('dashboard_chart_data', JSON.stringify($chart_data));

      // Generate highcharts series

      let $series = [];
      let $categories = [];
      let $category_year = [];
      let $unique_category_year = [];

      $chart_data.sort(function(a, b) {
        let a_data = a['data']['name'];
        let b_data = b['data']['name'];
        let $a_year = a_data.split(' ');
        let $b_year = b_data.split(' ');

        return $b_year[$b_year.length - 1] < $a_year[$a_year.length - 1] ? 1 : -1 || a_data - b_data;
      });

      $chart_data.forEach(function($item) {
        let $series_data = [];

        $item['data']['data'].forEach(function ($data_item) {
          let category = moment($data_item['timestamp'], 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM');
          let category_year = moment($data_item['timestamp'], 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY');

          if (!$category_year[category_year]) {
            $category_year.push(category_year);
          }

          $series_data.push([
            category,
            $data_item['value'],
          ]);

          $categories.push(category);
        });

        $series.push({
          'name': $item['data']['name'],
          'data': $series_data,
        });
      });

      function createLineHighCharts($series, $categories, $input) {
        initLineHighCharts('dashboard_high_charts', $series, {
          xAxis: {
            categories: $categories,
            title: {
              text: LANGUAGE['month'],
            }
          },
          yAxis: {
            tickInterval: null,
            crosshair: false,
            type: null,
            title: {
              text: $input.data('input-unit'),
            }
          },
          tooltip: {
            valueSuffix: ' ' + $input.data('input-unit'),
          },
        });
      }

      if (last_item) {
        $.each($category_year, function(i, el){
          if ($.inArray(el, $unique_category_year) === -1) $unique_category_year.push(el);
        });

        $('#chart_view').off().on('input', function() {
          sessionStorage.setItem('dashboard_chart_view', this.value);

          if (this.value === 'bar') {
            convertToBarChart($input, $unique_category_year.sort(), $series);
          }
          else {
            createLineHighCharts($series, $categories, $input);
          }
        });

        let dashboard_chart_view = sessionStorage.getItem('dashboard_chart_view', 'line');

        $('#chart_view').val(dashboard_chart_view);

        if (dashboard_chart_view === 'bar') {
          convertToBarChart($input, $unique_category_year.sort(), $series);
        }
        else {
          createLineHighCharts($series, $categories, $input);
        }
      }
    }

    function convertToBarChart($input, $categories, $series) {
      let $new_series = [];

      $series.forEach(function($item) {
        let value = 0;
        let $year = $item['name'].split(' ');
        let year = $year[$year.length - 1];
        let $new_data = [];

        $item['data'].forEach(function ($data) {
          value = value + $data[1]
        });

        $categories.forEach(function(category) {
          if (year === category) {
            $new_data.push(
              parseFloat(
                (Math.round(value * 100) / 100).toFixed(2)
              )
            );
          }
          else {
            $new_data.push(0);
          }
        });

        $new_series.push({
          name: $item['name'],
          data: $new_data,
        });
      });

      initBarHighCharts('dashboard_high_charts', $new_series, {
        xAxis: {
          categories: $categories,
          title: {
            text: null
          }
        },
        yAxis: {
          title: {
            text: $input.data('input-unit'),
          },
        },
        tooltip: {
          valueSuffix: ' ' + $input.data('input-unit'),
        },
      });
    }

    $.ajax({
      contentType: false,
      processData: false,
      type: 'POST',
      url: $form.attr('action'),
    }).done(function($data) {
      initChartView($data);

      let energy_type = $input.attr('data-energy-type');

      if ($data['chart_type'] == 'simulation' || $data['chart_type'] == 'energy_certificate') {
        let $chart_data2 = sessionStorage.getItem('dashboard_chart_data');

        if ($chart_data2) {
          $chart_data2 = JSON.parse($chart_data2);
        } else {
          $chart_data2 = []
        }

        let $chart_data2_item = {
          'chart_type': $data['chart_type'],
          'data': $data['data'],
          'id': id,
          'priority': $data['priority'],
        };

        if (is_checked) {
          let $ids = [];

          $chart_data2.forEach(function($item) {
            $ids.push($item['id']);
          });

          if ($ids.indexOf(id) < 0) {
            $chart_data2_item['category'] = $data['category'];
            $chart_data2.push($chart_data2_item);
          }
        }
        else {
          let $chart_data2_new = [];

          $chart_data2.forEach(function($item) {
            if ($item['id'] != id) {
              $chart_data2_new.push($item);
            }
          });

          $chart_data2 = $chart_data2_new;
        }

        sessionStorage.setItem('dashboard_chart_data', JSON.stringify($chart_data2));
      }

      let $chart_data3 = sessionStorage.getItem('dashboard_chart_data');
      let chart_data_length = 0;
      let $chart_types = [];

      if ($chart_data3) {
        $chart_data3 = JSON.parse($chart_data3);
        chart_data_length = $chart_data3.length;

        if (chart_data_length > 1) {
          $chart_data3.forEach(function($item) {
            if ($chart_types.indexOf($item['chart_type']) < 0) {
              $chart_types.push($item['chart_type']);
            }
          });
        }
      }

      function initBar() {
        let $chart_data = sessionStorage.getItem('dashboard_chart_data');

        if ($chart_data) {
          $chart_data = JSON.parse($chart_data);
        } else {
          $chart_data = []
        }

        let $chart_data_item = {
          'id': id,
          'data': $data['data'],
        };

        if (is_checked) {
          let $ids = [];

          $chart_data.forEach(function($item) {
            $ids.push($item['id']);
          });

          if ($ids.indexOf(id) < 0) {
            $chart_data_item['category'] = $data['category'];
            $chart_data.push($chart_data_item);
          }
        }
        else {
          let $chart_data_new = [];

          $chart_data.forEach(function($item) {
            if ($item['id'] != id) {
              $chart_data_new.push($item);
            }
          });

          $chart_data = $chart_data_new;
        }

        sessionStorage.setItem('dashboard_chart_data', JSON.stringify($chart_data));

        let $categories = [];
        let $series = [];
        let $series_data = {};

        $chart_data.forEach(function($item) {
          $categories.push($item['category']);

          $item['data'].forEach(function($item_data) {
            if (!$series_data[$item_data['name']]) {
              $series_data[$item_data['name']] = [];
            }

            if (!isNaN(parseFloat($item_data['value']))) {
              $series_data[$item_data['name']].push(parseInt($item_data['value']));
            }
          });
        });

        for (var key1 in $series_data) {
          $series.push({
            'name': key1,
            'data': $series_data[key1],
          })
        }

        // Filter empty values

        let $filtered_series = [];

        $series.forEach(function($serie) {
          let add_serie = false;

          $serie['data'].forEach(function(value) {
            if (value != 0) {
              add_serie = true;
            }
          });

          if (add_serie) {
            $filtered_series.push($serie);
          }
        });

        initStackedBarHighCharts('dashboard_high_charts', $filtered_series, {
          colors: ['#2266E5', '#F8942F'],
          xAxis: {
            categories: $categories,
          },
          yAxis: {
            title: {
              text: $input.data('input-unit'),
            },
          },
          tooltip: {
            valueSuffix: ' ' + $input.data('input-unit'),
          },
        });
      }

      function initColumn() {
        if (!is_checked) {
          $data['data'] = [];
        }

        $('#chart_view_wrapper').addClass('d-none');

        if (energy_type === 'PE' || energy_type === 'PEM' || energy_type === 'PES') {

          // Filter empty values

          let $filtered_data = [];

          $data['data'].forEach(function($item) {
            $item['data'].forEach(function(value) {
              if (value != 0) {
                $filtered_data.push($item);
                return;
              }
            });
          });

          initBarHighCharts('dashboard_high_charts', $filtered_data, {
            plotOptions: {
              column: {
                stacking: 'normal',
              }
            },
            tooltip: {
              valueSuffix: ' kWh/m²a',
            },
            colors: ['#69C3FB', '#242424', '#C4E53B'],
            xAxis: {
              categories: $data['category'],
              title: {
                text: null
              }
            },
            yAxis: {
              title: {
                text: $data['unit'],
              },
            },
          });
        }
        else {
          let $chart_data = sessionStorage.getItem('dashboard_chart_data');

          initBarHighCharts('dashboard_high_charts', $data['data'], {
            plotOptions: {
              column: {
                stacking: 'normal',
              }
            },
            tooltip: {
              valueSuffix: ' kWh/m²a',
            },
            xAxis: {
              categories: $data['category'],
              title: {
                text: null
              }
            },
            yAxis: {
              title: {
                text: $data['unit'],
              },
            },
          });
        }
      }

      if ($chart_types.length > 1 || (($data['chart_type'] == 'simulation' || $data['chart_type'] == 'energy_certificate') && (energy_type != 'PE' && energy_type != 'PEM' && energy_type !== 'PES'))) {
        let $chart_data_mix = sessionStorage.getItem('dashboard_chart_data');
        let $chart_data_mix_json = JSON.parse($chart_data_mix);
        let $categories_mix = [];
        let $series_mix = [];
        let $series_data_mix = {};
        let $colors = [];

        $chart_data_mix_json.sort(function(a, b) {
          let a_data = a['priority'];
          let b_data = b['priority'];

          return b_data < a_data ? 1 : -1;
        });

        let plot = true;

        console.log('energy_type', energy_type);
        const $stack = {};

        if (energy_type === 'PE' || energy_type === 'PEM' || energy_type === 'PES') {
          console.log('Code für PE Energieausweis und Simulation');

          if (energy_type === 'PES') {
            $colors = ['#2266E5', '#F8942F', '#69C3FB', '#242424', '#C4E53B']
          }
          else {
            $colors = ['#2266E5', '#69C3FB', '#242424', '#C4E53B']
          }

          $chart_data_mix_json.forEach(function($item) {
            if ($item['chart_type'] === 'energy_certificate') {
              $categories_mix.push($item['category']);

              $item['data'].forEach(function($item_data) {
                if (!$series_data_mix[$item_data['name']]) {
                  $series_data_mix[$item_data['name']] = [];
                }

                $series_data_mix[$item_data['name']].push(parseFloat($item_data['value']));
              });
            }
            else {
              $item['category'].forEach(function($category) {
                if ($categories_mix.indexOf($category) === -1) {
                  $categories_mix.push($category);
                }
              });

              $item['data'].forEach(function($item_data) {
                if (!$series_data_mix[$item_data['name']]) {
                  $series_data_mix[$item_data['name']] = [];
                }

                let has_value = false;

                $item_data['data'].forEach(function(value) {
                  if (value != 0) {
                    has_value = true;
                    $series_data_mix[$item_data['name']].push(parseFloat(value));
                    return;
                  }
                });

                if (!has_value) {
                  $series_data_mix[$item_data['name']].push(0);
                }
              });
            }
          });
        }
        else {
          console.log('Code für EE Energieausweis und Simulation');

          $colors = ['#ED3A12', '#2266E5', '#7FF82F'];

          let chart_counter = 0;

          $chart_data_mix_json.forEach(function($item) {
            if ($item['chart_type'] === 'energy_certificate') {
              $categories_mix.push($item['category']);

              $item['data'].forEach(function($item_data) {
                if (!$series_data_mix[$item_data['name']]) {
                  $series_data_mix[$item_data['name']] = [];
                }

                $stack[$item_data['name']] = $item_data['stack'];

                $series_data_mix[$item_data['name']].push(parseFloat($item_data['value']));
              });
            }
            else {
              chart_counter += 1;
              $item['data'].forEach(function($item_data) {
                if (!$series_data_mix[$item_data['name']]) {
                  $series_data_mix[$item_data['name']] = [];
                }

                $series_data_mix[$item_data['name']].push(parseFloat($item_data['data'][0]));
              });

              $categories_mix.push($item['category'][0]);
            }
          });

          // if (chart_counter === 0) {
          //   plot = false;
          // }
        }

        console.log($stack);

        for (var key in $series_data_mix) {
          if ($stack[key]) {
            $series_mix.push({
              'name': key,
              'data': $series_data_mix[key],
              'stack': 0,
            });
          }
          else {
            $series_mix.push({
              'name': key,
              'data': $series_data_mix[key],
            })
          }

        }

        console.log($series_mix)

        initStackedBarHighCharts('dashboard_high_charts', $series_mix, {
          colors: $colors,
          xAxis: {
            categories: $categories_mix,
          },
          yAxis: {
            title: {
              text: $data['unit'],
            },
          },
          tooltip: {
            valueSuffix: ' kWh/m²a',
          },
        }, plot);
      }
      else {
        if ($data['chart'] == 'bar') {
          console.log('InitBar');
          initBar();
        }
        else if ($data['chart'] == 'column') {
          console.log('InitColumn');
          initColumn();
        }
        else {
          console.log('calcLineHightChart');
          calcLineHightChart($data);
        }
      }
    });
  }
}

initDataTableAGWRDashboard();


// #############################################################################
// DATA TABLES: MY PORJECT

function initDataTableMyProject() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        class: 'd-none',
        data: 'name',
      },
      {
        class: 'align-middle',
        data: 'type',
      },
      {
        class: 'align-middle',
        data: 'content',
      },
      {
        class: 'align-middle text-nowrap text-right',
        data: 'status',
      },
      {
        class: 'align-middle',
        data: 'calc',
      },
      {
        class: 'align-middle',
        data: 'edit',
      },
      {
        class: 'align-middle',
        data: 'delete',
      },
      {
        class: 'align-middle',
        data: 'variant',
      },
    ],
    dom: '<"row"<"col-12"rt>',
    paging: false,
  };

  let $table = initDataTable('my-project-table', $options);

  function update_state() {
    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: '/geo/building/currentEntryState',  // TODO: Nicht hardcoded!
      success: function($data) {
        $.each(JSON.parse($data['calc_entries']), function(key, is_calculated) {
          var btn_detail = $('#btn_detail_' + key);
          $.each(btn_detail, function() {
            if (is_calculated == 'True') {
              $(this).removeClass('d-none');
            }
            else {
              $(this).addClass('d-none');
            }
          });
        });

        $.each(JSON.parse($data['active_entries']), function(key, value) {
          console.log(value, key);
        });

        $.each($data['not_active_entries'], function(index, value) {
          $('[data-calc="' + value + '"]').removeClass('disabled');
        });
      }
    });
  }

  if ($table) {
    $table.on('draw', function() {
      let last = null;

      let $rows = $table.rows({
        page: 'current',
      }).nodes();

      let $groups = $table.column(0, {
        page: 'current',
      }).data();

      $groups.each(function(group, i) {
        if (last !== group) {
          $($rows).eq(i).before(
            '<tr class="bg-gray-100"><th colspan="11">' + group + '</th></tr>'
          );

          last = group;
        }
      });

      let $btn_calc = $('[data-calc]');

      $btn_calc.on('click', function() {
        let $this = $(this);
        let entry_id = $this.data('data-calc');

        if (!$this.hasClass('disabled')) {
          $.ajax({
            url: $this.attr('href'),
            success: function() {
              update_state();

              let $data = [];
              $data['datatable'] = true; // TODO: In View anpassen!

              reloadDatatable($data);
            }
          });
        }

        $this.addClass('disabled');

        return false;
      });

      update_state();

      setInterval(function() {
        update_state();
      }, 5000);
    })
  }
}

initDataTableMyProject();


// #############################################################################
// DATA TABLES: READING

function initDataTableReading() {
  if (!$.fn.dataTable) {
    return;
  }

  let input_type = $('[data-input-type]').data('input-type');
  let $options = {};

  if (input_type === 0) {
    $options = {
      columns: [
        {
          data: 'date',
        },
        {
          data: 'time',
        },
        {
          class: 'text-right',
          data: 'value',
        },
        {
          data: 'public',
        },
        {
          data: 'approved',
        },
        {
          class: 'pl-0',
          data: 'edit',
        },
        {
          class: 'pl-0',
          data: 'delete',
        },
      ],
    };
  }
  else if (input_type === 1) {
    $options = {
      columns: [
        {
          data: 'date',
        },
        {
          class:'d-none',
          data: 'time',
        },
        {
          class: 'text-right',
          data: 'value',
        },
        {
          data: 'public',
        },
        {
          data: 'approved',
        },
        {
          class: 'pl-0',
          data: 'edit',
        },
        {
          class: 'pl-0',
          data: 'delete',
        },
      ],
    };
  }

  initDataTable('reading-table', $options);
}

initDataTableReading();


// #############################################################################
// DATA TABLES: READING CHART

function initDataTableReadingChart() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        data: 'date',
      },
      {
        data: 'time',
      },
      {
        class: 'text-right',
        data: 'value',
      },
      {
        class: 'd-none',
        data: 'raw_value',
      },
    ],
    ordering: false,
  };

  let $table = initDataTable('reading-chart-table', $options);

  if ($table) {
    $table.on('draw', function() {
      let $data = $table.data();

      let table = '<table id="consumption_high_charts_table">';

      table += '<thead><tr><th></th><th></th></tr></thead>';
      table += '<tbody>';

      $.each($data, function(index) {
        let datetime = $data[index]['date'] + ' ' + $data[index]['time'];
        let timestamp = (moment(datetime, 'DD. MMM YYYY HH:mm').unix() + 7200) * 1000;

        table += '<tr>';
        table += '<th>' + timestamp + '</th>';
        table += '<td>' + $data[index]['raw_value'] + '</td>';
        table += '</tr>';
      });

      table += '</tbody>';

      $('#consumption_high_charts_wrapper').empty().html(table);

      initHighCharts('consumption_high_charts', 'consumption_high_charts_table');
    });
  }
}

initDataTableReadingChart();


// #############################################################################
// DATA TABLES: EA LIST

function initDataTableEAMeasures() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        data: 'from',
      },
      {
        data: 'from_public',
      },
      {
        data: 'to',
      },
      {
        data: 'to_public',
      },
      {
        data: 'description',
      },
      {
        data: 'description_public',
      },
      {
        data: 'customer',
      },
      {
        data: 'customer_public',
      },
      {
        data: 'contact',
      },
      {
        data: 'contact_public',
      },
      {
        data: 'costs',
      },
      {
        data: 'costs_public',
      },
      {
        data: 'diagram'
      },
      {
        data: 'diagram_public'
      },
      {
        data: 'image'
      },
      {
        data: 'image_public'
      },
      {
        class: 'pl-0',
        data: 'edit',
      },
      {
        class: 'pl-0',
        data: 'delete',
      },
    ],
  };

  let $table = initDataTable('ea-table', $options);

  if ($table) {
    $table.on('draw', function() {
      $('[data-ea-public]').on('click', function() {
        let $input = $(this);
        let $form = $input.parents('form');

        $.ajax({
          contentType: false,
          processData: false,
          type: 'POST',
          url: $form.attr('action'),
        }).done(function($data) {
          reloadDatatable($data);
          updateToast($data);
        });
      });
    });
  }
}

initDataTableEAMeasures();


// #############################################################################
// DATA TABLES: GLOBAL EA LIST

function initDataTableGlobalEAMeasures() {
  if (!$.fn.dataTable) {
    return;
  }

  const $options = {
    columns: [
      {
        data: 'agwr',
      },
      {
        data: 'is_community_building'
      },
      {
        data: 'from',
      },
      {
        data: 'from_public',
      },
      {
        data: 'to',
      },
      {
        data: 'to_public',
      },
      {
        data: 'description',
      },
      {
        data: 'description_public',
      },
      {
        data: 'customer',
      },
      {
        data: 'customer_public',
      },
      {
        data: 'contact',
      },
      {
        data: 'contact_public',
      },
      {
        data: 'costs',
      },
      {
        data: 'costs_public',
      },
      {
        data: 'diagram'
      },
      {
        data: 'diagram_public'
      },
      {
        data: 'image'
      },
      {
        data: 'image_public'
      },
      {
        class: 'pl-0',
        data: 'edit',
      },
      {
        class: 'pl-0',
        data: 'delete',
      },
    ],
  };

  const $buttons = [
    {
      className: 'btn btn-secondary font-weight-bold',
      text: LANGUAGE['exportPDF'],
      action: function(e, $dt, $node, config) {
        const $header = $dt.header().toJQuery();
        const $tbody = $dt.body().toJQuery();
        const $tr= $("tr", $tbody);
        const $th = $("th", $header);
        const $json = [];

        const $th_index = [];
        const $th_text = [];

        $th.each(function (index) {
          if (!$th.eq(index).is('.no-export-pdf')) {
            $th_index.push(index);
          }
          $th_text.push($.trim($th.eq(index).text()));
        });

        $tr.each(function (index1) {
          const $td = $('td', $tr.eq(index1));

          let $item = [];

          $td.each(function (index2) {
            if ($th_index.indexOf(index2) > -1) {
              const $a = $('a', $td.eq(index2));

              if ($a.length) {
                const href = $a.attr('href');
                const $images = ["jpg", "png", "gif", "bmp", "tiff"];

                if ($images.indexOf(href.slice(-3)) > -1) {
                  $item.push([href.slice(-3), [$th_text[index2], href]]);
                }
                else {
                  $item.push([$th_text[index2], href]);
                }
              }
              else {
                let text = $td.eq(index2).text();

                if (text === '-') {
                  text = '';
                }

                $item.push([$th_text[index2], text]);
              }
            }
          });

          $json.push($item)
        });

        $.ajax({
          url: $('[data-export-pdf]').attr('data-export-pdf'),
          type: 'POST',
          data: {
            ea: JSON.stringify($json),
          },
          xhrFields: {
            responseType: 'blob',
          },
          success: function($data) {
            let url = window.URL || window.webkitURL;
            let $a = $('<a>');

            $('body').append($a);

            $a[0].href = url.createObjectURL($data);
            $a[0].download = 'EA-Export.pdf';
            $a[0].click();
            $a.remove();

            window.URL.revokeObjectURL(url);
          }
        });
      },
    },
  ];

  let $table = initDataTable('global-ea-table', $options, $buttons);

  if ($table) {
    $table.on('draw', function() {
      $('[data-ea-public]').on('click', function() {
        let $input = $(this);
        let $form = $input.parents('form');

        $.ajax({
          contentType: false,
          processData: false,
          type: 'POST',
          url: $form.attr('action'),
        }).done(function($data) {
          reloadDatatable($data);
          updateToast($data);
        });
      });
    });
  }
}

initDataTableGlobalEAMeasures();


// #############################################################################
// HIGH CHARTS

function initHighCharts(chart, table) {
  let $chart = $('#' + chart);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    }
  });

  Highcharts.chart(chart, {
    data: {
      table: table
    },
    chart: {
      type: 'area'
    },
    title: {
      text: '',
    },
    series: [
      {
        name: $chart.attr('data-series-name'),
      }
    ],
    xAxis: {
      tickInterval: 12 * 3600 * 1000,
      crosshair: true,
      type: 'datetime',
      title: {
        text: LANGUAGE['time'],
      },
    },
    yAxis: {
      tickInterval: null,
      crosshair: false,
      type: null,
      title: {
        text: $chart.attr('data-y-title'),
      }
    },
    credits: {
      enabled: false
    },
    colors: ['#5CA887', '#1D4A57'],
    legend: true,
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', ]
        }
      }
    },
  });
}


// #############################################################################
// HIGH CHARTS - JSON LINE CHART

function initLineHighCharts(chart, $series, $options) {
  let $chart = $('#' + chart);

  if ($series.length > 0) {
    $chart.parents('.high-charts').removeClass('d-none');
  }
  else {
    $chart.parents('.high-charts').addClass('d-none');
  }

  let $defaults = {
    title: {
      text: '',
    },
    yAxis: {
      tickInterval: null,
      crosshair: false,
      type: null,
    },
    series: $series,
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', ]
        }
      }
    },
  };

  $.extend($defaults, $options);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    }
  });

  Highcharts.chart(chart, $defaults);
}


// #############################################################################
// HIGH CHARTS - JSON BAR CHART

function initBarHighCharts(chart, $series, $options) {
  let $chart = $('#' + chart);

  if ($series.length > 0) {
    $chart.parents('.high-charts').removeClass('d-none');
  }
  else {
    $chart.parents('.high-charts').addClass('d-none');
  }

  let $defaults = {
    chart: {
      type: 'column'
    },
    title: {
      text: '',
    },
    xAxis: {
      tickInterval: null,
      crosshair: false,
      type: null,
    },
    series: $series,
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', ]
        }
      },
    },
  };

  $.extend($defaults, $options);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    }
  });

  Highcharts.chart(chart, $defaults);
}

function initStackedBarHighCharts(chart, $series, $options, plot = true) {
  let $chart = $('#' + chart);

  if ($series.length > 0) {
    $chart.parents('.high-charts').removeClass('d-none');
  }
  else {
    $chart.parents('.high-charts').addClass('d-none');
  }

  let $defaults = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      }
    },
    yAxis: {
      tickInterval: null,
      crosshair: false,
      type: null,
    },
    series: $series,
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadSVG', ]
        }
      },
    },
  };

  $.extend($defaults, $options);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    }
  });

  Highcharts.chart(chart, $defaults);
}


// #############################################################################
// DASHBOARD EXPORT

function initDashboardExport() {
  let $dashboard_export = $('[data-dashboard-export]');

  if ($dashboard_export.length === 0) {
    return;
  }

  function getImage($chart, callback) {
    let $svg = $chart.getSVG();
    let $canvas = document.createElement('canvas');

    $canvas.width = 800;
    $canvas.height = 500;

    let $ctx = $canvas.getContext('2d');
    let $img = document.createElement('img');

    $img.onload = function() {
      $ctx.drawImage($img, 0, 0);
      callback($canvas.toDataURL('image/png'));
    };

    $img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent($svg))));
  }

  function getCSV($chart) {
    return $chart.getCSV()
  }

  $dashboard_export.on('click', function() {
    let $chart = $('#dashboard_high_charts').highcharts();

    getImage($chart, function(chart) {
      $.ajax({
        url: $dashboard_export.attr('href'),
        type: 'POST',
        data: {
          chart: chart.split('base64,')[1],
          csv: getCSV($chart),
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: function($data) {
          let url = window.URL || window.webkitURL;
          let $a = $('<a>');

          $('#dashboard_export_link').empty().append($a);

          $a[0].href = url.createObjectURL($data);
          $a[0].download = 'Dashboard-Export.xlsx';
          $a[0].click();

          window.URL.revokeObjectURL(url);
        }
      });
    });

    return false;
  });
}

initDashboardExport();


// #############################################################################
// FANCY BOX

function initFancyBox() {
  var $fancybox = $('[data-fancybox]');

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    buttons: [
      'download',
      'fullScreen',
      'close',
    ],
    protect: true,
    afterLoad : function(instance, current) {
        var pixel_ratio = window.devicePixelRatio || 1;

        if ( pixel_ratio > 1.5 ) {
            current.width  = current.width  / 2;
            current.height = current.height / 2;
        }
    }
  });
}

initFancyBox();


// #############################################################################
// OPEN LAYERS MAP

(function ($) {

  var pluginName = 'initialiseMap';
  var map, untiled, tiled, layer_vector, default_style, styles_energy,
    colors_energy, agwr, projection;
  var vectorlayer;

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$select_output = $('#output_type', this.$el);
    this.$legend_type = $('#legend_type', this.$el);
    this.$legend_form = $('#legend_form', this.$el);
    this.$energy_data = '';
    this.$building_data = '';


    this.init();
  }

  $.fn.extend(Plugin.prototype, {
    init: function () {
      var _this = this;


//##############################################################################
// MAP STYLES
      colors_energy = ['red', 'orange', 'yellow', 'green'];
      styles_energy = [];

      $.each(colors_energy, function (index, value) {
        var stroke = new ol.style.Stroke({color: 'black', width: 2});
        var fill = new ol.style.Fill({color: value});

        styles_energy.push(
          new ol.style.Style({
            image: new ol.style.RegularShape({
              fill: fill,
              stroke: stroke,
              points: 4,
              radius: 7,
              angle: Math.PI / 4
            })
          })
        );
      });
      colors_energy.reverse();

      default_style = [
        new ol.style.Style({
          fill: new ol.style.Fill({
            color: 'rgba(0, 0, 255, 0.1)'
          })
        })
      ];


//##############################################################################
// POPUP

var popup = document.getElementById('popup');
var content = document.getElementById('popup-content');
var closer = document.getElementById('popup-closer');


/**
 * Create an overlay to anchor the popup to the map.
 */
var overlay = new ol.Overlay({
  element: popup,
  autoPan: true,
  autoPanAnimation: {
    duration: 250
  }
});

 /**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function() {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};


//##############################################################################
// MAP LAYERS

      var format = 'image/png';
      var bounds = [1815805.5218636736, 6161462.301623501,
                    1819605.6317388455, 6167021.594331044];
      //SUBZONENLAYER
      var sub = new ol.layer.Tile({
        visible: true,
        source: new ol.source.TileWMS({
          url: 'https://geo.way2smart.at/geoserver/way2smart/wms',
          params: {'FORMAT': format,
                   'VERSION': '1.1.1',
                   tiled: true,
                'LAYERS': 'way2smart:w2s_api_subzone',
                'exceptions': 'application/vnd.ogc.se_inimage',
             tilesOrigin: 1815805.5218636736 + ',' + 6161462.301623501
          }
        })
      });

      // AGWR LAYER
       agwr = new ol.layer.Tile({
        visible: true,
        source: new ol.source.TileWMS({
          url: 'https://geo.way2smart.at/geoserver/way2smart/wms',
          params: {'FORMAT': format,
                   'VERSION': '1.1.1',
                   tiled: true,
                'LAYERS': 'way2smart:agwr_clean',
                'exceptions': 'application/vnd.ogc.se_inimage',
             tilesOrigin: 1815805.5218636736 + ',' + 6161462.301623501
          }
        })
      });

      projection = new ol.proj.Projection({
        code: 'EPSG:3857',
        units: 'm',
        global: true
      });

      untiled = new ol.layer.Image({
                source: new ol.source.ImageWMS({
                    ratio: 1,
                    url: 'https://geo.way2smart.at/geoserver/way2smart/wms',
                    params: {
                        'FORMAT': format,
                        'VERSION': '1.1.1',
                        LAYERS: 'way2smart:agwr_clean',
                      tilesOrigin: 1815805.5218636736 + ',' + 6161462.301623501
                    }

                })
            });
      tiled = new ol.layer.Tile({
          visible: false,
          source: new ol.source.TileWMS({
              url: 'https://geo.way2smart.at/geoserver/way2smart/wms',
              params: {
                  'FORMAT': format,
                  'VERSION': '1.1.1',
                  tiled: true,
                  LAYERS: 'way2smart:agwr_clean',
                  tilesOrigin: 1815805.5218636736 + ',' + 6161462.301623501
              }
          })
      });

      // BASELAYER
      var baseLayer = new ol.layer.Tile({source: new ol.source.OSM()});
      map = new ol.Map({
        layers: [
          baseLayer,
          sub,
          agwr,
          // untiled,
          // tiled,
            // agwr_2019,
        ],

        overlays :[overlay],
        target: 'map',
        view: new ol.View({
          projection: projection,
          maxResolution:10
        })
      });

      // var $legend_type = $('#legend_type');
      var $select_output = $('#id_legend_type');

      map.getView().on('change:resolution', function () {
        // var resolution = evt.target.get('resolution');
        // // console.log(resolution);
      });
      map.getView().fit(bounds, map.getSize());

      // CLEAR LEGEND AFTER YEAR
      $('#id_year').on('change', function(evt){
        $select_output.val('');
         _this.updateEnergyScenarioLayerMap(evt);
      })

      $select_output.on('change', function(evt) {
        // console.log('Hallo Change');
        $.getJSON('data_json_jahressummen', {'year': $('#id_year').find(':selected').val()}, function(data) {
          _this.$energy_data = data;
        });
        $.getJSON('data_json_buildings', {'year': $('#id_year').find(':selected').val()}, function(data) {
        _this.$building_data = data;
      });
        _this.updateEnergyScenarioLayerMap(evt);
      });

      //Gebäudeeigenschaften
      // $legend_type.on('change', function (evt) {
      //   _this.updateEnergyScenarioLayer(evt);
      // });

      // Todo: Bei onchange dazu + Jahr aus Select
      //Jahressummen Energiekennzahlen
      $.getJSON('data_json_jahressummen', {'year': $('#id_year').find(':selected').val()}, function(data) {
        // console.log('Data', data);
        _this.$energy_data = data;
      });

      //Gebäudeeigenschaften
      $.getJSON('data_json_buildings', {'year': $('#id_year').find(':selected').val()}, function(data) {
        // console.log('Data: ', data);
        _this.$building_data = data;
      });

      //map events
      map.on('singleclick', function (evt) {
        content.innerText = '';
       _this.loadBuildingData(evt, map);

        var coordinate = evt.coordinate;
        // var hdms = ol.proj.toLonLat(coordinate);

        overlay.setPosition(coordinate);

      });
      },

// #############################################################################
    // Wahrscheinlich Energieszenarien

    getEnergyOutputValue: function (buildingId, output_parameter) {
      var _this = this;
      var return_value;
      var test = _this.$energy_data[buildingId];
      if (test !== undefined) {
        if (test[output_parameter] >= 0) {
          return_value = test[output_parameter];
        }
      }
      return return_value;
    }
    ,

    addMapLayer: function (range, output_parameter) {
      var _this = this;
      var getCategoryStyle = function (feature) {
        // var id = feature.getId().split('.')[1];
        var id = feature.get("UID");
        var value = _this.getEnergyOutputValue(id, output_parameter);
        var style = default_style;
        var helper = false;
        var size = range.length;
        console.log("Value: ",value);
        if (value !== undefined) {
          // console.log('??', _this.$building_data[id]['hauptgebaude'] );
          if(_this.$building_data[id] !== undefined){
          if (_this.$building_data[id]['hauptgebaude'] !== '0') {
            if (size > 0) {
              $.each(range, function (index, val) {
                if (value < val) {
                  style = styles_energy[index];
                  helper = true;
                }
              });
              if (helper === false && value > range[0]) {
                style = styles_energy[0];
                helper = true;
              }
            } else {
              style = styles_energy[styles_energy.length - 1];
            }
          }
          return style;
        }
          }

      };

      if (vectorlayer !== null) {
        map.removeLayer(vectorlayer);
      }

      vectorlayer = new ol.layer.Vector({
        source: new ol.source.Vector({
          url: 'https://geo.way2smart.at/geoserver/way2smart/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=way2smart:agwr_clean&maxFeatures=10000&outputFormat=application%2Fjson',
          format: new ol.format.GeoJSON(),
        }),
        projection: projection,
        style: getCategoryStyle,
      });
      // map.removeLayer(agwr);
      map.addLayer(vectorlayer);
      console.log("Added Layer");
      vectorlayer.getSource().refresh();
      console.log("refresh");
      window.requestAnimationFrame(afterRedrawr);

      function afterRedrawr () {
        console.log("Redraw");
      }

    },

    getEnergyOutputValueRange: function (output_parameter) {
      var _this = this;
      var year = $('#id_year').find(':selected').val();
      // console.log(output_parameter);
      // console.log(year);
      $.ajax({
        url: 'get_output_parameter_range',
        type: 'POST',
        data: {
          // 'output_parameter_id': output_parameter_id,
          'output_parameter': output_parameter,
          'year': year,
        },

        success: function(data) {
          // console.log('Success');
          var html = '';
          var cnt = 0;
          var $legend_form = $('#legend_form');
          if (Object.keys(data).length >= 1) {
            $legend_form.html('<div class="badge badge-pill" style="width: 100%;text-align:center; background-color:' + colors_energy[0] + '">' + '0' + ' ' + data.einheit + '</div>');

            $.each(data.range_values, function(index) {
              if(index === 0) {
                html += '<div class="badge badge-pill" style="width: 100%;text-align:center; background-color:' + colors_energy[index] + '">' + '< ' + data.range_values[index] + ' ' + data.einheit + '</div>';
              } else {
                html += '<div class="badge badge-pill" style="width: 100%;text-align:center; background-color:' + colors_energy[index] + '">' + data.range_values[index] + ' ' + data.einheit + '</div>';
              }

              cnt = cnt + 1;
            });

            if(html !== '') {
              $legend_form.html(html);
            }

            _this.addMapLayer(data.range_values.reverse(), output_parameter);
          } else {
            map.removeLayer(vectorlayer);
            $legend_form.html(html);
          }
        }
      });
    }
    ,

    getLegendFormValue: function (buildingId, output_parameter) {
      var _this = this;
      var return_value = '';
      var test = _this.$building_data[buildingId];
      output_parameter = output_parameter.toLowerCase();
      if (test !== undefined) {
        if (test[output_parameter] !== undefined) {
          return_value = test[output_parameter];
        }
      }
      // console.log('Return Value', return_value);
      return return_value;
    },

    updateEnergyScenarioLayer: function () {
      var _this = this;
      var colors = ['red', 'blue', 'yellow', 'green', 'orange', 'gray', 'pink', 'Olive', 'Lime', 'Aqua', 'Teal', 'Purple', 'Maroon', 'Fuchsia', 'Silver'];
      var styles = [];

      $.each(colors, function (index, value) {

        var stroke = new ol.style.Stroke({color: 'black', width: 2});
        var fill = new ol.style.Fill({color: value});

        styles.push(
          new ol.style.Style({
            image: new ol.style.RegularShape({
              fill: fill,
              stroke: stroke,
              points: 4,
              radius: 7,
              angle: Math.PI / 4
            })
          })
        );
      });

      var output_parameter = $('#id_legend_type').find(':selected').val();
      console.log('Output Param', output_parameter);
      var legend_forms = [];
      var cnt = 0;
      var html = '';

      var getCategoryStyle = function(feature) {
        var id = feature.get("UID");

        var output = _this.getLegendFormValue(id, output_parameter);
        var style = default_style;
        if(id == 2947 || id == 2942){
          console.log("ID: ", id, "Value: ", output);
        }

        // Super magic thing because the data gets manipulated in some view, so there is the needed mapping
        if(output !== '' && output !== undefined) {
          console.log("Feature", feature);
          console.log("Output", output);
          console.log(id);
          if(output === 'Mehrfamilienhaus') {
            output = 'MFH__Bestand_Way2Smart';
          } else if(output === 'Doppelhause') {
            output = 'Doppelhaus_Maulpertsch';
          } else if(output === 'Einfamilienhaus') {
            output = 'EFH_Freistehend';
          } else if(output === 'Reihenhaus') {
            output = 'Reihenhaus_Bestand';
          }
          $.each(legend_forms, function(index, value) {
            if (value === "Fernwaerme") {
              console.log(output, value, index, id);
            }


            if(output === value) {
              if(id == 2217 || id == 5205) {
                console.log("#########");
                console.log(id, output, colors[index], style[index], feature);
              }
              // console.log('Output/Value2', output, value, id);
              if(id == 92) {
                console.log(colors[index]);
              }
              style = styles[index];
            }
          });

        }
        if(id == 2947 || id == 2942 || id == 2217){
          console.log("ID: ", id, "Value: ", output, "Style", style);
        }

        return style;
      };
      // console.log("Param", output_parameter);
      $.ajax({
        url: 'get_legend_forms',
        type: 'POST',
        data: {'legend_form': output_parameter},
        success: function(data) {
          var values = jQuery.parseJSON(data);
          legend_forms = values;
          $.each(values, function (index, value) {
            html += '<span class="badge badge-pill" style="width: 100%;text-align:center; background-color:' + colors[index] + '">' + value + '</span> ';
            cnt = cnt + 1;
          });
          var $legend_form = $('#legend_form');
          $legend_form.html(html);

          if (vectorlayer !== null && vectorlayer !== undefined) {
            map.removeLayer(vectorlayer);
          }

          vectorlayer = new ol.layer.Vector({
            source: new ol.source.Vector({
              url: 'https://geo.way2smart.at/geoserver/way2smart/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=way2smart:agwr_clean&maxFeatures=10000&outputFormat=application%2Fjson',
              format: new ol.format.GeoJSON()
            }),
            style: getCategoryStyle
          });

          map.addLayer(vectorlayer);
        }
      });
    },

    updateEnergyScenarioLayerMap: function () {
      var _this = this;
      var output_parameter= $('#id_legend_type').find(':selected').val();
      // console.log('Test: ', output_parameter);
      // var output_parameter = '';
      //
      // $.ajax({
      //   url: 'get_output_parameter_value',
      //   type: 'POST',
      //   data: {
      //     'output_parameter_id': output_parameter_id,
      //     'type': 'energy'
      //   },
      //   success: function(data) {
      //     output_parameter = data;
      if(output_parameter !== 'Geometrietyp' && output_parameter !== 'Versorgungstyp') {
        _this.getEnergyOutputValueRange(output_parameter);
      } else {
        _this.updateEnergyScenarioLayer();
      }
      //   }
      // });

    },


    getBuildingInfo: function (id, subzonen_id, gs_id, output_parameter) {
      var _this = this;
      var html_content = '';
      $.ajax({
        url: '/geo/get_building_info',
        method: 'POST',
        data: {
          'gebid': id,
          'subzonenid': subzonen_id,
          'gs_id': gs_id,
          'output_parameter': output_parameter
        },
        success: function(data) {
          html_content += data.infobox;

        },
        error: function () {
          html_content += 'Keine Karteninformationen vorhanden';
          _this.$content.html(html_content);
        }
      });
    }
    ,

    loadBuildingData: function (evt, map) {
      /*Gebäudeinformationen abrufren */
      var view = map.getView();
      var viewResolution = view.getResolution();
      var source = untiled.get('visible') ? untiled.getSource() : tiled.getSource();

      // Feature Info geoserver (404)
      var _url = source.getGetFeatureInfoUrl(
        evt.coordinate, viewResolution, view.getProjection(), {
          'INFO_FORMAT': 'application/json',
          'FEATURE_COUNT': 50
        }
      );

      if (layer_vector !== null) {
        map.removeLayer(layer_vector);
      }

      var output_parameter_id = $('#output_type').val();
      var output_parameter = '';

      /*get output parameter value*/
      $.ajax({
        url: 'get_output_parameter_value',
        type: 'POST',
        data: {'output_parameter_id': output_parameter_id},
        success: function(data) {
          output_parameter = data;
          // console.log("Data", data);
        }
      });

      /*Loading map features*/
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        loader: function (extent) {
          var url = _url;
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url);

          var onError = function () {
            vectorSource.removeLoadedExtent(extent);
          };

          // Todo: Layer 404
          xhr.onerror = onError;
          xhr.onload = function () {
            if (xhr.status == 200) {

              vectorSource.addFeatures(
                vectorSource.getFormat().readFeatures(xhr.responseText)
              );

              $.each(vectorSource.getFeatures(), function (index, feature) {
                var feature_id = feature.getId().split('.');
                var uid = feature.get('UID');

                var host_name = window.location.hostname;
                var host_port = window.location.port;
                var host_protocol = window.location.protocol;
                var content = document.getElementById('popup-content');


                fetch(host_protocol + '//' + host_name + ':' + host_port + '/building/agwr/get/' + uid).then(function(response) {
                  return response.text()
                }).then(function(popup) {
                  content.innerHTML = popup
                })

              });
            } else {
              onError();
            }
          };
          xhr.send();
        },
        strategy: ol.loadingstrategy.bbox
      });

      var setBuildingStyle = function () {
        var style = default_style;
        return style
      };

      layer_vector = new ol.layer.Vector({
        source: vectorSource,
        style: setBuildingStyle
      });
      map.addLayer(layer_vector);
    }
  });

  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };

})(jQuery);

$('.map-control-wrapper-build').initialiseMap();


// #############################################################################
// TINY MCE

function initHtmlTextarea ($parent = $body) {
  const $textareas = $("[data-html-textarea]", $parent);

  $textareas.each(function (index) {
    const $textarea = $textareas.eq(index);
    let $tinymce = $textarea.tinymce();

    if ($tinymce) {
      $tinymce.remove();
    }

    $textarea.tinymce({
      branding: false,
      // content_css: "/static/css/tinymce.min.css",
      height: 250,
      inline_styles: false,
      language: $("html").attr("lang"),
      menubar: false,
      mode: "specific_textareas",
      plugins: "autolink contextmenu lists spellchecker wordcount",
      toolbar: "styleselect | bold | numlist | bullist | link | undo redo cut copy paste pastetext | removeformat",
      style_formats: [
        {
          title: "Paragraph",
          block: "p",
        },
        {
          title: "Header 2",
          block: "h2",
        },
        {
          title: "Header 3",
          block: "h3",
        },
        {
          title: "Header 4",
          block: "h4",
        },
      ],
      valid_elements: "h2,h3,h4,p,strong,li,ul,ol",
      valid_styles: "+a[id|rel|",
      setup: function ($editor) {
        const $textarea = $("#" + $editor.id);

        if ($textarea.attr("disabled")) {
          $editor.settings.readonly = true;
        }
      },
    });
    $tinymce = $textarea.tinymce();

    $tinymce.on("change", function () {
      tinymce.triggerSave();
    });

    $tinymce.on("focus", function () {
      const $textarea = $("#" + $tinymce.id);
      const $container = $(".mce-tinymce", $textarea.parents(".form-group"));

      $container.addClass("focus");
    });

    $tinymce.on("blur", function () {
      const $textarea = $("#" + $tinymce.id);
      const $container = $(".mce-tinymce", $textarea.parents(".form-group"));

      $container.removeClass("focus");
    });
  });
}
